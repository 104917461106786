/* eslint-disable react-refresh/only-export-components */
import type { PropifyRoute } from '@propify-tenant-client/layouts';
import { lazy } from 'react';

const TermsAndConditions = lazy(() =>
  import('@propify-tenant-client/common').then((module) => ({
    default: module.TermsAndConditionsPage,
  })),
);
const ApplicationWizardLayoutWrapper = lazy(
  () => import('@/layouts/ApplicationWizardLayoutWrapper'),
);
const BasicLayoutWrapper = lazy(() => import('@/layouts/BasicLayoutWrapper'));
const UserLayoutWrapper = lazy(() => import('@/layouts/UserLayoutWrapper'));
const DocumentLayout = lazy(() =>
  import('@propify-tenant-client/layouts').then((module) => ({ default: module.DocumentLayout })),
);
const PaymentLayoutWrapper = lazy(() => import('@/layouts/PaymentLayoutWrapper'));
const SecurityLayout = lazy(() => import('@/layouts/SecurityLayoutWrapper'));
const AdditionalUsersPage = lazy(() => import('@/pages/additional-users/AdditionalUsersPage'));
const AlmostDone = lazy(() =>
  import('@propify-tenant-client/account').then((module) => ({ default: module.AlmostDone })),
);
const Dashboard = lazy(() => import('@/pages/dashboard'));
const DeactivateLead = lazy(() =>
  import('@propify-tenant-client/units').then((module) => ({
    default: module.DeactivateLead,
  })),
);
const PrivacyPoliciesPage = lazy(() =>
  import('@propify-tenant-client/common').then((module) => ({
    default: module.PrivacyPoliciesPage,
  })),
);
const SMSTermsOfService = lazy(() =>
  import('@propify-tenant-client/common').then((module) => ({
    default: module.SMSTermsOfServicePage,
  })),
);
const LeadQualificationPage = lazy(() => import('@/pages/form/LeadQualificationPage'));
const Home = lazy(() => import('@/pages/Home'));
const LockCodesPage = lazy(() => import('@/pages/lock-codes/LockCodesPage'));
const CreateWorkOrderPage = lazy(() => import('@/pages/maintenance/CreateWorkOrderPage'));
const MaintenanceRequestDetailsPage = lazy(
  () => import('@/pages/maintenance/MaintenanceRequestDetailsPage'),
);
const RequestsPage = lazy(() => import('@/pages/maintenance/MaintenanceRequestsPage'));
const AutopayPage = lazy(() => import('@/pages/payment/AutopayPage'));
const BraintreeClientPage = lazy(() => import('@/pages/payment/BraintreeClientPage'));
const MakePaymentPage = lazy(() => import('@/pages/payment/MakePaymentPage'));
const PaymentHistoryPage = lazy(() =>
  import('@propify-tenant-client/payments').then((module) => ({
    default: module.PaymentHistory,
  })),
);
const ResidentLedgerPage = lazy(() => import('@/pages/payment/ResidentLedgerPage'));
const PaymentMethodsPage = lazy(() => import('@/pages/payment/payment-methods/PaymentMethodsPage'));
const CommonDoorPage = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.CommonDoor,
  })),
);
const ShowingCompletedPage = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.ShowingCompleted,
  })),
);
const ContactSupportPage = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.ContactSupport,
  })),
);
const PreShowingFraudPreventionPage = lazy(
  () => import('@/pages/showing/check-in/PreShowingFraudPreventionPage'),
);
const UnlockCodePage = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.UnlockCode,
  })),
);
const UnlockUnit = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.UnlockUnit,
  })),
);
const EditShowingPage = lazy(() => import('@/pages/showing/EditShowingPage'));
const PastShowingsPage = lazy(() => import('@/pages/showing/PastShowingsPage'));
const ScheduleShowingPage = lazy(() => import('@/pages/showing/ScheduleShowingPage'));
const ShowingSuccessPage = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.ShowingSuccess,
  })),
);
const UpcomingShowingsPage = lazy(() => import('@/pages/showing/UpcomingShowingsPage'));
const UnlockShowing = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.UnlockShowing,
  })),
);
const TemperatureProfilesPage = lazy(
  () => import('@/pages/temperature-profiles/TemperatureProfilesPage'),
);
const ThermostatSchedulePage = lazy(() => import('@/pages/thermostat/ThermostatSchedulePage'));
const UnitDetailsPage = lazy(() => import('@/pages/unit/UnitDetailsPage'));
const UnitSearchPage = lazy(() => import('@/pages/unit/UnitSearchPage'));
const CreateUnitApplicationPage = lazy(() =>
  import('@propify-tenant-client/applications').then((module) => ({
    default: module.CreateUnitApplication,
  })),
);
const UnitApplicationsPage = lazy(() =>
  import('@propify-tenant-client/applications').then((module) => ({
    default: module.UnitApplications,
  })),
);
const UnitApplicationProcessPage = lazy(
  () => import('@/pages/unit-applications/UnitApplicationProcessPage'),
);
const AccountsHomePage = lazy(() => import('@/pages/user/home/AccountsHomePage'));
const LoginPage = lazy(() => import('@/pages/user/login/LoginPage'));
const PasswordResetConfirmPage = lazy(
  () => import('@/pages/user/passwordReset/PasswordResetConfirmPage'),
);
const PasswordResetRequestPage = lazy(
  () => import('@/pages/user/passwordReset/PasswordResetRequestPage'),
);
const VerifyEmailPage = lazy(() => import('@/pages/user/verification/email/VerifyEmailPage'));
const VerifyTokenPage = lazy(() => import('@/pages/user/verification/email/VerifyTokenPage'));
const VerifyPersonaPage = lazy(() => import('@/pages/user/verification/persona/VerifyPersonaPage'));
const RevalidateMessagePage = lazy(
  () => import('@/pages/user/verification/revalidate/RevalidateMessagePage'),
);
const VerifyPhonePage = lazy(() => import('@/pages/user/verification/sms/VerifyPhonePage'));
const WaitingListPage = lazy(() => import('@/pages/waiting/WaitingListPage'));
const RenewalApplicationProcessPage = lazy(
  () => import('@/pages/renewal-applications/RenewalApplicationProcessPage'),
);
const RenewalTermsPage = lazy(() => import('@/pages/renewal-applications/RenewalTermsPage'));
const RenewalsPage = lazy(() =>
  import('@propify-tenant-client/renewals').then((module) => ({
    default: module.Renewals,
  })),
);

const leasesSign = lazy(() => import('@/pages/leases/LeasesSignPage'));
const MyLeasesPage = lazy(() => import('@/pages/leases/MyLeasesPage'));

export const routes: PropifyRoute[] = [
  {
    path: '/user',
    component: UserLayoutWrapper,
    routes: [
      {
        path: '/user',
        redirect: '/user/login',
      },
      {
        path: '/user/login',
        component: LoginPage,
      },
      {
        path: '/user/password-reset/confirm',
        component: PasswordResetConfirmPage,
      },
      {
        path: '/user/password-reset',
        component: PasswordResetRequestPage,
      },
    ],
  },
  {
    path: '/deactivate-lead',
    component: DeactivateLead,
  },
  {
    path: '/showings/unlock',
    component: UnlockShowing,
  },
  {
    path: '/documents',
    component: DocumentLayout,
    routes: [
      {
        path: '/documents/privacy-policy',
        component: PrivacyPoliciesPage,
      },
      {
        path: '/documents/terms-and-conditions',
        component: TermsAndConditions,
      },
      {
        path: '/documents/sms-terms-of-service',
        component: SMSTermsOfService,
      },
    ],
  },
  {
    path: '/',
    component: SecurityLayout,
    routes: [
      {
        path: '/',
        component: BasicLayoutWrapper,
        routes: [
          {
            path: '/units',
            component: UnitSearchPage,
          },
          {
            path: '/units/:id',
            component: UnitDetailsPage,
          },
          {
            path: '/lead-qualification-form',
            component: LeadQualificationPage,
          },
          {
            path: '/dashboard',
            component: Dashboard,
            requirements: ['emailVerified'],
          },
          {
            path: '/',
            component: Home,
            requirements: ['emailVerified'],
          },
          {
            path: '/account',
            routes: [
              {
                path: '/account',
                component: AccountsHomePage,
              },
              {
                path: '/account/verification/sms',
                component: VerifyPhonePage,
              },
              {
                path: '/account/verification/email/:token',
                component: VerifyTokenPage,
              },
              {
                path: '/account/verification/email',
                component: VerifyEmailPage,
              },
              {
                path: '/account/verification/revalidate',
                component: RevalidateMessagePage,
              },
              {
                path: '/account/verification/payments',
                component: BraintreeClientPage,
              },
              {
                path: '/account/verification/persona',
                component: VerifyPersonaPage,
              },
            ],
          },
          {
            path: '/showings',
            requirements: ['emailVerified', 'isProspect'],
            routes: [
              {
                path: '/showings/upcoming',
                component: UpcomingShowingsPage,
              },
              {
                path: '/showings/past',
                component: PastShowingsPage,
              },
              {
                path: '/showings/success',
                component: ShowingSuccessPage,
              },
              {
                path: '/showings/edit',
                component: EditShowingPage,
              },
              {
                path: '/showings/check-in/common-door',
                component: CommonDoorPage,
              },
              {
                path: '/showings/check-in/pre-showing',
                component: PreShowingFraudPreventionPage,
              },
              {
                path: '/showings/check-in/unlock-code',
                component: UnlockCodePage,
              },
              {
                path: '/showings/check-in/unlock-unit',
                component: UnlockUnit,
              },
              {
                path: '/showings/check-in/contact-support',
                component: ContactSupportPage,
              },
              {
                path: '/showings/check-in/completed',
                component: ShowingCompletedPage,
              },
              {
                path: '/showings/schedule',
                component: ScheduleShowingPage,
              },
            ],
          },
          {
            path: '/waiting',
            requirements: ['emailVerified', 'isProspect'],
            routes: [
              {
                path: '/waiting',
                component: WaitingListPage,
              },
            ],
          },
          {
            path: '/thermostat',
            requirements: ['hasSmartHomeEnabled', 'emailVerified'],
            routes: [
              {
                path: '/thermostat/schedule',
                component: ThermostatSchedulePage,
              },
            ],
          },
          {
            path: '/maintenance',
            requirements: ['hasMaintenanceEnabled', 'emailVerified'],
            routes: [
              {
                path: '/maintenance/requests',
                component: RequestsPage,
              },
              {
                path: '/maintenance/requests/create',
                component: CreateWorkOrderPage,
              },
              {
                path: '/maintenance/requests/:id',
                component: MaintenanceRequestDetailsPage,
              },
            ],
          },
          {
            path: '/lock-codes',
            component: LockCodesPage,
            requirements: ['hasSmartHomeEnabled', 'emailVerified'],
          },
          {
            path: '/temperature-profiles',
            component: TemperatureProfilesPage,
            requirements: ['hasSmartHomeEnabled', 'emailVerified'],
          },
          {
            path: '/almost',
            component: AlmostDone,
            requirements: ['emailVerified'],
          },
          {
            path: '/additional-users',
            component: AdditionalUsersPage,
            requirements: ['hasSmartHome', 'emailVerified'],
          },
          {
            path: '/payments',
            component: PaymentLayoutWrapper,
            requirements: ['emailVerified', 'isResident'],
            routes: [
              {
                path: '/payments/make-payment',
                component: MakePaymentPage,
              },
              {
                path: '/payments/autopay',
                component: AutopayPage,
                requirements: ['hasAutopayEnabled'],
              },
              {
                path: '/payments/payment-methods',
                component: PaymentMethodsPage,
              },
              {
                path: '/payments/history',
                component: PaymentHistoryPage,
              },
              {
                path: '/payments/ledger',
                component: ResidentLedgerPage,
              },
            ],
          },
          {
            path: '/renewal-terms/:renewalId',
            component: RenewalTermsPage,
          },
        ],
      },
      {
        path: '/renewals',
        requirements: ['emailVerified'],
        routes: [
          {
            path: '/renewals/:id',
            component: ApplicationWizardLayoutWrapper,
            routes: [
              {
                path: '/renewals/:id',
                component: RenewalApplicationProcessPage,
              },
            ],
          },
          {
            path: '/renewals',
            component: BasicLayoutWrapper,
            routes: [
              {
                path: '/renewals/',
                component: RenewalsPage,
              },
            ],
          },
        ],
      },
      {
        path: '/',
        component: SecurityLayout,
        routes: [
          {
            path: '/',
            component: BasicLayoutWrapper,
            routes: [
              {
                path: '/renewal-terms/:renewalId',
                component: RenewalTermsPage,
              },
              {
                path: '/leases/:id/sign',
                component: leasesSign,
              },
              {
                path: '/my-leases',
                component: MyLeasesPage,
              },
            ],
          },
        ],
      },
      {
        path: '/unit-applications',
        component: ApplicationWizardLayoutWrapper,
        requirements: ['emailVerified'],
        routes: [
          {
            path: '/unit-applications/unit/:unitId/create',
            component: CreateUnitApplicationPage,
            requirements: ['emailVerified'],
          },
          {
            path: '/unit-applications/:id',
            component: UnitApplicationProcessPage,
            requirements: ['emailVerified'],
          },
        ],
      },
      {
        path: '/applications',
        component: BasicLayoutWrapper,
        requirements: ['emailVerified'],
        routes: [
          {
            path: '/applications',
            component: UnitApplicationsPage,
            requirements: ['emailVerified'],
          },
        ],
      },
    ],
  },
];
