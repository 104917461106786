import App from '@/app';
import '@/global.less';
import '@/global.tsx';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
